import React from 'react'
import PropTypes from 'prop-types'
import track from 'react-tracking'
import GlobalLayout from 'components/GlobalLayout'
import { stampTime, eventDispatch } from 'utils/tracker'

const NotFoundPage = ({ location }) => (
	<GlobalLayout location={location}>
		<div>
			<h1>NOT FOUND</h1>
			<p>You just hit a route that doesn&#39;t exist... the sadness.</p>
		</div>
	</GlobalLayout>
)
NotFoundPage.propTypes = {
	location: PropTypes.object,
}
export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(NotFoundPage)
